<template>

  <div>
    <modal-add-infraccion @actualizarTablaInfracciones="actualizarTablaInfracciones" />

    <b-card
      no-body
      class="mb-0 px-2"
      :class="`border-danger`"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                size="md"
                type="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="400"
              />
              <b-button
                variant="outline-success"
                @click="nuevaInfraccionMod"
              >
                <span class="text-nowrap">Nueva infracción</span>
                <!-- <feather-icon icon="PlusSquareIcon" /> -->
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        id="my-table"
        ref="refTablaInfracciones"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        small
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :no-provider-filtering="true"
        :fields="tableColumns"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :height="myProvider.length < 5 ? '200px' : null"
      >

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>
        <!-- Estudiante -->
        <template #cell(index)="data">
          {{ from + data.index }}
        </template>
        <!-- Estudiante -->
        <template #cell(estudiante)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :variant="`light-primary`"
                :text="avatarText(data.item.persona_infractor.apellidos)"
              />
            </template>

            <strong class="text-primary">{{ data.item.persona_infractor.apellidos }} {{ data.item.persona_infractor.nombres }}</strong>
            <br>
            <small> <strong>DNI: </strong> {{ data.item.persona_infractor.numero_documento }}</small>
            <br>
            <small> <strong>ETNIA: </strong> {{ data.item.persona_infractor.pueblo_indigena.nombre }}</small>
          </b-media>

        </template>
        <template #cell(fecha)="data">
          {{ new Intl.DateTimeFormat('es-PE',{month: 'long', day:'numeric', year:'numeric'}).format(new Date(`${data.item.fecha}T00:00:00`)) }} <br>
        </template>
        <!-- Column: servicios -->
        <template #cell(servicio)="data">
          <feather-icon
            :icon="resolveServicioIcon(data.item.servicio.codigo)"
            size="15"
            class="mr-50"
          />
          {{ data.item.servicio.nombre }}

        </template>

        <template #cell(naturaleza)="data">
          {{ data.item.naturaleza.nombre }}

        </template>

        <template #cell(gravedad)="data">
          <b-badge
            pill
            :variant="`light-${resolveEstadoVariant(data.item.gravedad.codigo)}`"
            class="text-capitalize"
          >
            {{ data.item.gravedad.nombre }}
          </b-badge>
        </template>
        <!-- Column: estado -->
        <template #cell(acciones)="data">

          <span><feather-icon
            :icon="data.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
            size="19"
            @click="data.toggleDetails"
          /></span>

          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item

              @click="editarInfraccion(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item

              @click="eliminarInfraccion(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template
          #row-details="data"
          class="m-0 p-0"
        >
          <b-card class="m-0 p-0 border">
            <b-row>
              <b-col
                cols="12"
                class="text-center mb-1"
              >
                <span class="text-primary"><strong>SERVIDOR QUE REGISTRÓ INFRACCIÓN: </strong> </span>
                <span class="ml-1">{{ data.item.persona_registrador.name }}</span>
              </b-col>
              <b-col
                cols="12"
                class="mb-1"
              >

                <li><span class="text-primary"><strong>DESCRIPCIÓN: </strong> </span></li>
                <span class="ml-2">{{ data.item.descripcion }}</span>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BSpinner, BMedia, BAvatar,
  BBadge, BDropdown, BDropdownItem, BPagination, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import infraccionesStoreModule from './infraccionesStoreModule'
import ModalAddInfraccion from './ModalAddInfraccion.vue'

export default {
  components: {
    BMedia,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BSpinner,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    vSelect,

    // externs
    ModalAddInfraccion,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [50, 100, 300, 500],
      sortBy: 'id',
      sortDesc: false,
      filter: '',
      filterOn: [],

    }
  },
  methods: {
    actualizarTablaInfracciones() {
      this.filter = null
      this.$refs.refTablaInfracciones.refresh()
    },
    async myProvider(ctx) {
      const promise = store.dispatch('infracciones-store-module/getInfracciones', { queryParams: ctx })

      return promise.then(response => {
        // console.log('responseInfracciones: ', response)
        const {
          data, total, from, to,
        } = response.data
        const items = data
        this.totalRows = total || 0
        this.from = from || 0
        this.to = to || 0

        return items || []
      })
    },
    nuevaInfraccionMod() {
      this.$emit('nuevaInfraccion')
    },
    editarInfraccion(item) {
      this.$emit('editarInfraccionMod', item)
    },
    eliminarInfraccion(id) {
      this.$swal({
        title: '¿Esta seguro de eliminar el registro de la infracción?',
        text: 'considere que esta acción es irreversible',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('infracciones-store-module/deleteInfraccion', id)
            .then(() => {
              this.actualizarTablaInfracciones()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Infracción eliminado.',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
            // eslint-disable-next-line no-unused-vars
            }).catch(error => {
              // console.log(error)
            })
        }
      })
    },
  },
  setup() {
    const CONVOCATORIA_APP_STORE_MODULE_NAME = 'infracciones-store-module'

    // Register module
    if (!store.hasModule(CONVOCATORIA_APP_STORE_MODULE_NAME)) store.registerModule(CONVOCATORIA_APP_STORE_MODULE_NAME, infraccionesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONVOCATORIA_APP_STORE_MODULE_NAME)) store.unregisterModule(CONVOCATORIA_APP_STORE_MODULE_NAME)
    })

    const tableColumns = [

      { key: 'index', label: 'N°' },
      { key: 'estudiante', label: 'estudiante', sortable: true },
      { key: 'fecha', label: 'fecha', sortable: true },
      { key: 'servicio', label: 'servicio', sortable: true },
      { key: 'naturaleza', label: 'naturaleza', sortable: true },
      { key: 'gravedad', label: 'gravedad', sortable: true },
      { key: 'acciones', label: 'acciones', sortable: true },
    ]

    const resolveEstadoVariant = status => {
      if (status === 'FALTA-LEVE') return 'primary'
      if (status === 'FALTA-GRAVE') return 'warning'
      if (status === 'FALTA-EXCLUYENTE') return 'danger'
      return 'primary'
    }

    const resolveServicioIcon = value => {
      if (value === 'BECA-COMEDOR') return 'CoffeeIcon'
      if (value === 'BECA-RESIDENCIA') return 'HomeIcon'
      if (value === 'BECA-BOLSA') return 'BriefcaseIcon'

      return 'UserIcon'
    }

    return {
      infraccionesStoreModule,
      tableColumns,
      avatarText,
      resolveServicioIcon,
      resolveEstadoVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
