<template>
  <b-modal
    id="modal_add_infraccion"
    ok-only
    size="lg"
    :header-bg-variant="cabezeraModal"
    hide-footer
  >
    <template
      #modal-header
    >
      <div class="text-white">
        <strong class="float-left">
          {{ nombreModal }}
        </strong></div>

    </template>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <buscar-alumno
        v-if="vamosCrear"
        @alumnoSeleccionado="alumnoSeleccionado"
      />
      <div class="border rounded py-1">
        <h3 class="text-center text-primary">
          <strong>ESTUDIANTE:</strong> <span class=" ml-1 text-align-center text-primary">{{ estudianteSelect.nombres }}
            - <span class="text-danger">{{ estudianteSelect.numero_documento === '' ? 'sin documento' :estudianteSelect.numero_documento }}</span>
          </span>
        </h3>

      </div>
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- SERVICIOS -->
        <b-row>
          <b-col
            md="6"
            sm="12"
          >
            <validation-provider
              #default="validationContext"
              name="selectedServicio"
              rules="required"
            >
              <b-form-group
                label="Seleccione el servicio"
                label-for="selectedServicio"
              >
                <b-form-select
                  id="selectedServicio"
                  v-model="infraccion.servicio_id"
                  :options="servicioOptions"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  Este campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <validation-provider
              #default="validationContext"
              name="selectedFecha"
              rules="required"
            >
              <b-form-group
                label="Fecha de infracción"
                label-for="selectedFecha"
              >
                <b-form-datepicker
                  id="example-datepicker"
                  v-model="infraccion.fecha"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  Este campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>

          <!-- NATURALEZA -->
          <b-col
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="selectedNaturaleza"
              rules="required"
            >
              <b-form-group
                label="Seleccione la naturaleza de la falta"
                label-for="selectedNaturaleza"
              >
                <b-form-select
                  id="selectedNaturaleza"
                  v-model="infraccion.naturaleza_id"
                  :options="naturalezaOptions"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  Este campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- GRAVEDAD -->
          <b-col
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="selectedGravedad"
              rules="required"
            >
              <b-form-group
                label="Seleccione la gravedad de la falta"
                label-for="selectedGravedad"
              >
                <b-form-select
                  id="selectedGravedad"
                  v-model="infraccion.gravedad_id"
                  :options="optionsGravedad"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  Este campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <!-- Descripción -->
            <validation-provider
              #default="validationContext"
              name="descripcion"
              rules="required"
            >
              <b-form-group
                label="Descripción (opcional)"
                label-for="descripcion"
              >
                <b-form-textarea
                  id="descripcion"
                  v-model="infraccion.descripcion"
                  size="sm"
                  :state="getValidationState(validationContext)"
                  placeholder="Describa aquí.."
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  Este campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Form Actions -->
        <div class="d-flex justify-content-between mt-3">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-danger"
            size="sm"
            @click="$bvModal.hide('modal_add_infraccion')"
          >
            <feather-icon
              icon="XIcon"
              size="14"
            />
            Cancelar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="`outline-${cabezeraModal}`"
            class="mr-2"
            type="submit"
          >
            {{ nombreBotonModal }}
            <feather-icon
              :icon="vamosCrear ? 'SaveIcon' : 'RefreshCcwIcon'"
              size="14"
            />
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import store from '@/store'
import {
  BButton, BModal, BForm, BFormGroup, BFormInvalidFeedback, BFormTextarea,
  BCol, BRow, BFormSelect, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BuscarAlumno from './BuscarAlumno.vue'

export default {
  components: {
    BFormDatepicker,
    BFormSelect,
    BButton,
    BCol,
    BRow,
    BModal,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
    BuscarAlumno,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      avatarText,
      required,
      vamosCrear: true,
      nombreModal: '',
      nombreBotonModal: '',
      cabezeraModal: '',
      infraccion: { usuario_id: '' },
      gravedadOptions: [],
      servicioOptions: [],
      naturalezaOptions: [],
      estudianteSelect: { usuario_id: '' },
    }
  },
  mounted() {
    store.dispatch('infracciones-store-module/getGravedad').then(res => {
      this.optionsGravedad = res.data.map(value => ({
        value: value.id, text: value.nombre,
      }))
    })
    store.dispatch('infracciones-store-module/getServicios').then(res => {
      this.servicioOptions = res.data.map(value => ({
        value: value.id, text: value.nombre,
      }))
    })
    store.dispatch('infracciones-store-module/getNaturaleza').then(res => {
      this.naturalezaOptions = res.data.map(value => ({
        value: value.id, text: value.nombre,
      }))
    })
  },
  created() {
    this.$parent.$on('editarInfraccionMod', this.editarInfraccionMod)
    this.$parent.$on('nuevaInfraccion', this.nuevaInfraccion)
  },
  methods: {
    alumnoSeleccionado(item) {
      this.$set(this.estudianteSelect, 'nombres', `${item.nombre_completo}`)
      this.$set(this.estudianteSelect, 'usuario_id', item.id)
      this.$set(this.estudianteSelect, 'numero_documento', item.numero_documento)
    },

    onSubmit() {
      if (this.estudianteSelect.usuario_id === undefined && this.vamosCrear) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: '¡Advertencia!',
            text: 'Debe de seleccionar a un estudiante',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (this.vamosCrear) {
        this.createInfraccion()
      } else {
        this.updateInfraccion()
      }
    },
    createInfraccion() {
      this.infraccion.usuario_id = this.estudianteSelect.usuario_id
      store.dispatch('infracciones-store-module/addInfraccion', this.infraccion)

        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Infracción registrada!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$emit('actualizarTablaInfracciones')
          this.$bvModal.hide('modal_add_infraccion')
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Error!',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateInfraccion() {
      const data = {
        id: this.estudianteSelect.id,
        data: this.infraccion,
      }
      store.dispatch('infracciones-store-module/updateInfraccion', data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Infraccion actualizada!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$emit('actualizarTablaInfracciones')
          this.$bvModal.hide('modal_add_infraccion')
        }).catch(error => {
          if (error.response.data.code === 422) {
            if (typeof (error.response.data.error) === 'object') {
              Object.entries(error.response.data.error).forEach(([key, value]) => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `¡Error en ${key}!`,
                    text: `${value}`,
                    icon: 'CheckCircleIcon',
                    variant: 'warning',
                  },
                })
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: '¡Advertencia!',
                  text: `${error.response.data.error}`,
                  icon: 'CheckCircleIcon',
                  variant: 'warning',
                },
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: '¡Error en en actualizar!',
                icon: 'CheckCircleIcon',
                variant: 'warning',
              },
            })
          }
        })
    },
    limpiarFormulario() {
      this.infraccion = {
      }
      this.estudianteSelect = {}
    },
    nuevaInfraccion() {
      this.nombreModal = 'Nueva Infracción'
      this.nombreBotonModal = 'Grabar'
      this.cabezeraModal = 'primary'
      this.vamosCrear = true

      this.limpiarFormulario()
      this.$bvModal.show('modal_add_infraccion')
    },

    editarInfraccionMod(dataEditInfraccion) {
      this.nombreModal = 'Actualizar Infracción'
      this.nombreBotonModal = 'Actualizar'
      this.cabezeraModal = 'warning'

      this.vamosCrear = false
      this.limpiarFormulario()
      this.infraccion.usuario_id = dataEditInfraccion.usuario_id
      this.infraccion.fecha = dataEditInfraccion.fecha
      this.infraccion.servicio_id = dataEditInfraccion.servicio_id
      this.infraccion.naturaleza_id = dataEditInfraccion.naturaleza_id
      this.infraccion.gravedad_id = dataEditInfraccion.gravedad_id
      this.infraccion.descripcion = dataEditInfraccion.descripcion
      this.$set(this.estudianteSelect, 'nombres', `${dataEditInfraccion.persona_infractor.apellido_paterno} ${dataEditInfraccion.persona_infractor.apellido_materno} ${dataEditInfraccion.persona_infractor.nombres}`)
      this.$set(this.estudianteSelect, 'id', dataEditInfraccion.id)
      this.$set(this.estudianteSelect, 'numero_documento', dataEditInfraccion.persona_infractor.numero_documento)
      this.$bvModal.show('modal_add_infraccion')
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

}
</script>

<style lang="scss" scoped>
</style>
